const ATTRIBUTE = "data-highlight"
const SELECTOR = `[${ATTRIBUTE}]`
const CLASSES = [ "transition", "duration-1000", "ease-in" ]

export function useHighlights (document) {
  const elements = document.querySelectorAll(SELECTOR)
  const observer = new IntersectionObserver(handler)

  elements.forEach(element => observer.observe(element))
}

function getValues (target) {
  return target.getAttribute(ATTRIBUTE).split(" ")
}

function addClasses (target) {
  target.classList.add(...CLASSES, ...getValues(target))
}

function removeClasses (target) {
  target.classList.remove(...CLASSES, ...getValues(target))
}

function handler (entries) {
  entries.forEach(handleEntry)
}

function handleEntry (entry) {
  if (entry.isIntersecting) {
    addClasses(entry.target)
  }
  else {
    removeClasses(entry.target)
  }
}
